var Project = require("./project");

Project.Request = {};
Project.Request.get = function (id) {
    return $.ajax({
        url: '/ajax/request/get.php',
        type: 'post',
        data: { id: id }
    });
};
Project.Request.checkMWS = function () {
    return $.ajax({
        url: '/ajax/request/mws.php',
        type: 'get'
    });
};
Project.Request.updateStatus = function (data) {
    return $.ajax({
        url: '/ajax/request/status.php',
        type: 'get',
        data: data
    });
};
Project.Request.refreshStatus = function () {
    var requests = [];
    var $statuses = $('.status');
    if ($statuses.length > 0) {
        $statuses.each(function () {
            requests.push($(this).data('request'));
        });
        Project.Request.updateStatus({ requests: requests }).then(function (response) {
            $.each(response.statuses, function (key, result) {
                if (result.status === 'COMPLETED' || result.status === 'FAILED') {
                    $('.status[data-request=' + result.id + ']').replaceWith(result.html);
                } else {
                    $('.status[data-request=' + result.id + '] .status-content').html(result.html);
                }
            });
        });
    }
};