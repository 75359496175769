var Project = require("./project");

Project.Notifications = {};
Project.Notifications.toast = function (message, type) {
    toastr[type](message);
};
Project.Notifications.error = function (message) {
    toastr['error'](message);
};
Project.Notifications.success = function (message) {
    toastr['success'](message);
};
Project.Notifications.info = function (message) {
    toastr['info'](message);
};
Project.Notifications.warning = function (message) {
    toastr['warning'](message);
};