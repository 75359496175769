var Project = {};
Project.debounce = function (fn, delay) {
    var timer = null;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};
Project.init = function () {
    Project.Modal.init();

    $(document).ajaxError(function (event, xhr, settings) {
        //Displays notification.
        if (xhr.status === 503) {
            Project.Notifications.error(xhr.responseJSON.message);
            if (typeof xhr.responseJSON.redirect !== 'undefined') {
                setTimeout(function () {
                    window.location.href = xhr.responseJSON.redirect;
                }, 5000);
            }
        }
    });

};
module.exports = Project;