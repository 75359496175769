var Project = require("./project");

Project.Modal = {};
Project.Modal.load = function (url) {
    return $.get(url);
};
Project.Modal.showFlash = function (message, type, icon) {
    type = type || 'danger';
    icon = icon || 'warning';
    var html = '<div class="flash"><div class="blink alert alert-' + type +  '" role="alert">' +
        '<i class="fa fa-' + icon + ' text-' + type +  '"></i> ' + message + ' </div></div>';

    $('.modal.in').find('.modal-body').prepend(html);
};
Project.Modal.hideFlash = function () {
    $('.modal.in .flash').remove();
};
Project.Modal.init = function () {
    //Modal opening.
    $(document).on('show.bs.modal', '.modal', function (event) {
        var $button = $(event.relatedTarget);
        var $modal = $(this);
        if ($button.data('class')) {
            $modal.addClass($button.data('class')).data('class', $button.data('class'));
        }
        if ($button.data('modal-size')) {
            $modal.data('modal-size', $button.data('modal-size'))
                .find('.modal-dialog').addClass($button.data('modal-size'));
        }
        if ($button.attr('href')) {
            Project.Modal.load($button.attr('href'))
                .success(function (response) {
                    $modal.data('initial-content', $modal.find('.modal-content').html());
                    $modal.addClass('loaded').find('.modal-content').html(response);
                    $modal.trigger($.Event('loaded.bs.modal'));
                });
        }
    }).on('hidden.bs.modal', '.modal', function () {
        var $modal = $(this);

        //Resets modal.
        $modal.removeData("bs.modal");
        if ($modal.data('class')) {
            $modal.removeClass($modal.data('class'));
        }
        if ($modal.data('modal-size')) {
            $modal.find('.modal-dialog').removeClass($modal.data('modal-size'));
        }
        if ($modal.hasClass('loaded')) {
            $modal.find('.modal-content').html($modal.data('initial-content'));
        }
    });
};