var Project = require("./project/project");

$(function () {

    toastr.options.closeButton = true;
    toastr.options.progressBar = true;
    toastr.options.positionClass = 'toast-top-center';

    Dropzone.autoDiscover = false;

    // Tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // Popovers
    $('[data-toggle="popover"]').popover();

    // Selects
    $('[data-toggle="select"]').material_select();

    // Side navigation
    $('.button-collapse').sideNav();

    new WOW().init();

    Project.init();

});