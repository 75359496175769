var Project = require("./project");

Project.Sequencer = {};
Project.Sequencer.CSV = {};
Project.Sequencer.CSV.upload = function (selector) {
    var upload = new Dropzone(selector, {
        url: '/ajax/sequencer/csv/upload.php',
        maxFilesize: 20,
        clickable: '.btn-upload',
        previewTemplate: $(selector).find('.preview-template').html()
    });
    upload.on("addedfile", function() {
        if (this.files[1] != null) {
            this.removeFile(this.files[0]);
        }
        var $preview = $('#'+ this.element.id).find('.dz-preview').show();
        $preview.find('.processing').hide();
        $preview.find('.uploading').show();
    });
    upload.on('sending', function(file, xhr, formData) {
        var $input = $(selector).parents('form').find('input[name=postcard_id]');
        formData.append($input.attr('name'), $input.val());
    });
    upload.on('success', function (file) {
        var serverResponse = JSON.parse(file.xhr.response);
        var $upload = $(this.element);
        $upload.find('> .dz-preview').fadeOut('slow', function () {
            Project.Notifications.success(serverResponse.message);
            setTimeout(function () {
                window.location.href = serverResponse.href;
            }, 5000);
        });
    });
    upload.on('error', function (file, error) {
        if (file.xhr) {
            var serverResponse = JSON.parse(file.xhr.response);
            Project.Notifications.error(serverResponse.message);
        } else {
            Project.Notifications.error(error);
        }
        this.removeFile(file);
    });
    upload.on("totaluploadprogress", function(progress) {
        var $preview = $('#'+ this.element.id).find('.dz-preview');
        var text = Math.ceil(progress) + '%';
        if (progress == 100) {
            $preview.find('.uploading').hide();
            $preview.find('.processing').show();
        } else {
            $preview.find('.uploading-progress').html(text);
        }
    });
};