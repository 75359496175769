var Project = require("./project");

Project.Postcard = {};
Project.Postcard.Selection = {};
Project.Postcard.Selection.actions = function ($postcards, $action) {
    if ($postcards.find('input[type=checkbox]:checked').length > 0) {
        if ($postcards.filter('.queued').length === 0) {
            $action.find('a[data-action=hold]').hide();
        } else {
            $action.find('a[data-action=hold]').show();
        }
        if ($postcards.filter('.hold').length === 0) {
            $action.find('a[data-action=queue]').hide();
        } else {
            $action.find('a[data-action=queue]').show();
        }
        $action.fadeIn();
    } else {
        $action.fadeOut();
    }
};
Project.Postcard.Selection.init = function () {
    var $action = $('.checkbox-action').on('click', '.dropdown-item', function (event) {
        event.preventDefault();

        var $form = $('#postcards-form');
        $form.find('input[name=action]').val($(this).data('action'));
        $form.submit();
    });

    var $postcards = $('.postcard');
    $postcards.on('click', 'input[type=checkbox]', function () {
        Project.Postcard.Selection.actions($postcards, $action);
    });

    $('.checkbox-selector').on('click', '.dropdown-item', function (event) {
        event.preventDefault();

        if ($(this).attr('href').replace('#', '') === 'all') {
            $postcards.find('input[type=checkbox]').prop("checked", true);
        } else if ($(this).attr('href').replace('#', '') === 'none') {
            $postcards.find('input[type=checkbox]').prop("checked", false);
        } else if ($(this).attr('href').replace('#', '') === 'hold') {
            $postcards.find('input[type=checkbox]').prop("checked", false);
            $postcards.filter('.hold').find('input[type=checkbox]').prop("checked", true);
        } else if ($(this).attr('href').replace('#', '') === 'queued') {
            $postcards.find('input[type=checkbox]').prop("checked", false);
            $postcards.filter('.queued').find('input[type=checkbox]').prop("checked", true);                }

        Project.Postcard.Selection.actions($postcards, $action);
    });
};
Project.Postcard.Edition = {};
Project.Postcard.Edition.background = function (selector, url) {
    var upload = new Dropzone(selector, {
        url: url,
        acceptedFiles: "image/*",
        maxFilesize: 20,
        clickable: '.btn-upload',
        previewTemplate: $(selector).find('.preview-template').html()
    });
    upload.on("addedfile", function() {
        if (this.files[1] != null) {
            this.removeFile(this.files[0]);
        }
        var $preview = $('#'+ this.element.id).find('.dz-preview');
        $preview.find('.processing').hide();
        $preview.find('.uploading').show();
    });
    upload.on('sending', function(file, xhr, formData) {
        formData.append('id', $(selector).parents('form').find('input[name=id]').val());
    });
    upload.on('success', function (file) {
        var serverResponse = JSON.parse(file.xhr.response);
        var src = serverResponse.path;
        var $side = $(this.element);
        var $preview = $side.find('> .dz-preview');
        $side.data('background-image', src).css('background-image', 'url(' + src + ')');
        $preview.find('.processing').fadeOut('slow');
        Project.Postcard.Edition.border($side);
    });
    upload.on('error', function (file, error) {
        if (file.xhr) {
            var serverResponse = JSON.parse(file.xhr.response);
            Project.Notifications.error(serverResponse.message);
        } else {
            Project.Notifications.error(error);
        }
        this.removeFile(file);
    });
    upload.on("totaluploadprogress", function(progress) {
        var $preview = $('#'+ this.element.id).find('.dz-preview');
        var text = Math.ceil(progress) + '%';
        if (progress == 100) {
            $preview.find('.uploading').hide();
            $preview.find('.processing').show();
        } else {
            $preview.find('.uploading-progress').html(text);
        }
    });
};
Project.Postcard.Edition.createBlock = function (postcard, type, side) {
    var index = 0;
    if ($('.block').length > 0) {
        index = parseInt($('.block:last-child').attr('id').replace('block-', '')) + 1;
    }

    return $.ajax({
        url: '/ajax/postcard/create.php',
        type: 'get',
        data: { postcard: postcard, type: type, side: side, index: index }
    }).then(function (response) {
        Project.Notifications.success(response.message);
        var $block = $(response.html).hide().appendTo($('.safe-zone-edition'));
        $(response.form).hide().appendTo($('.blocks-edition'));
        $block.fadeIn(function () {
            Materialize.updateTextFields();
            $block.trigger('click');
            $(document).trigger('postcard.' + side + '.updated', { block: index, action: 'created' });
        });
    });
};
Project.Postcard.Edition.border = function ($side) {
    $side.find('img.color-thief').remove();
    if ($side.data('background-image').length > 0) {
        $('<img src="'+ $side.data('background-image') +'" class="color-thief" />').load(function() {
            $(this).css({ position: 'absolute', top: '-9999px' }).appendTo($side);
            var colorThief = new ColorThief();
            var img = colorThief.getColor($(this)[0]);
            var colors = tinycolor({ r: img[0], g: img[1], b: img[2] }).monochromatic();
            var color = colors[0].toHexString();
            var better = tinycolor.readability({ r: img[0], g: img[1], b: img[2] }, colors[0]);
            colors.map(function(t) {
                var readability = tinycolor.readability({ r: img[0], g: img[1], b: img[2] }, t);
                if (readability > better) {
                    better = readability;
                    color = t.toHexString();
                }
            });

            var border = '1px dashed ' + color;
            $side.find('.safe-zone').css('border', border);
            $side.find('.block').on('mouseover', function () {
                $(this).css('border', border);
            }).on('mouseout', function () {
                $(this).css('border', '1px dashed transparent');
            });
        });
    }
};
Project.Postcard.Edition.image = function (id, side) {
    var $block = $('#block-' + id);
    var clickable = '.btn-upload-' + id;
    var upload = new Dropzone($block[0], {
        url: '/ajax/postcard/' + side + '/image.php',
        acceptedFiles: "image/*",
        maxFilesize: 20,
        clickable: clickable,
        previewTemplate: $block.find('.preview-template').html()
    });
    upload.on("addedfile", function() {
        if (this.files[1] != null) {
            this.removeFile(this.files[0]);
        }
        var $preview = $('#'+ this.element.id).find('.dz-preview');
        $preview.find('.processing').hide();
        $preview.find('.uploading').show();
    });
    upload.on('sending', function(file, xhr, formData) {
        formData.append('id', $block.parents('form').find('input[name=id]').val());
        formData.append('index', id);
    });
    upload.on('success', function (file) {
        var serverResponse = JSON.parse(file.xhr.response);
        var $upload = $(this.element);
        var $img = $('<img/>').attr('src', serverResponse.path).addClass('z-depth-0').hide();
        var $preview = $upload.find('> .dz-preview');
        $preview.find('.processing').fadeOut('slow', function () {
            if (serverResponse.height > serverResponse.width) {
                $img.css('height', '100%');
            } else {
                $img.css('width', '100%');
            }

            if ($upload.find('> img').length > 0) {
                $upload.find('> img').fadeOut(function () {
                    $(this).remove();
                    $upload.append($img.fadeIn(function () {
                        if (serverResponse.height > serverResponse.width) {
                            $block.css('width', 'auto');
                        } else {
                            $block.css('height', 'auto');
                        }
                        $('input[name="blocks[' + id + '][css][width]"]').val($block.width() + 'px');
                        $('input[name="blocks[' + id + '][css][height]"]').val($block.height() + 'px');
                        $('input[name="blocks[' + id + '][src]"]').val(serverResponse.path);
                        $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
                    }));
                });
            } else {
                $upload.append($img.fadeIn(function () {
                    if (serverResponse.height > serverResponse.width) {
                        $block.css('width', 'auto');
                    } else {
                        $block.css('height', 'auto');
                    }
                    $('input[name="blocks[' + id + '][css][width]"]').val($block.width() + 'px');
                    $('input[name="blocks[' + id + '][css][height]"]').val($block.height() + 'px');
                    $('input[name="blocks[' + id + '][src]"]').val(serverResponse.path);
                    $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
                }));
            }
        });
    });
    upload.on('error', function (file, error) {
        if (file.xhr) {
            var serverResponse = JSON.parse(file.xhr.response);
            Project.Notifications.error(serverResponse.message);
        } else {
            Project.Notifications.error(error);
        }
        this.removeFile(file);
    });
    upload.on("totaluploadprogress", function(progress) {
        var $preview = $('#'+ this.element.id).find('.dz-preview');
        var text = Math.ceil(progress) + '%';
        if (progress == 100) {
            $preview.find('.uploading').hide();
            $preview.find('.processing').show();
        } else {
            $preview.find('.uploading-progress').html(text);
        }
    });
};
Project.Postcard.Edition.initBlock = function (id, side) {
    var $block = $('#block-' + id);
    var $form = $('#block-' + id + '-edition');
    var type = $form.data('type');

    //Bind events on block.
    $block.on('keyup', function (event) {
        if (event.which === 46) {
            $form.find('.btn-delete').click();
        }
    });
    $block.draggable({
        containment: ".safe-zone-edition",
        delay: 100,
        stop: function() {
            $('input[name="blocks[' + id + '][css][left]"]').val($block.position().left + 'px');
            $('input[name="blocks[' + id + '][css][top]"]').val($block.position().top + 'px');
            $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'dragged' });
        }
    });
    if (type === 'image' || type === 'text' || type === 'qr') {
        var resizableOptions = {
            containment: ".safe-zone-edition",
            autoHide: true,
            handles: 'all',
            stop: function (event, ui) {
                $('input[name="blocks[' + id + '][css][width]"]').val(ui.size.width + 'px');
                $('input[name="blocks[' + id + '][css][height]"]').val(ui.size.height + 'px');
                $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'resized' });
            }
        };
        if (type === 'image') {
            resizableOptions.aspectRatio = true;
            resizableOptions.handles = 'ne, se, sw, nw';
        } else if (type === 'qr') {
            resizableOptions.aspectRatio = true;
            resizableOptions.handles = 'ne, se, sw, nw';
            resizableOptions.minWidth = 100;
            resizableOptions.resize = function (event, ui) {
                $block.find('img').css({ 'width': ui.size.width + 'px', 'height': ui.size.height + 'px'});
            };
        }
        $block.resizable(resizableOptions);
    }

    //Bind events on block edition.
    $form.on('click', '.btn-delete', function (event) {
        event.preventDefault();

        $form.fadeOut(function () {
            $form.remove();
            $('.block-edition-default').fadeIn();
            $block.fadeOut(function () {
                $block.remove();
                $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'deleted' });
            });
        });
    });
    $form.find("[data-toggle=select]").material_select();
    $form.find("[data-toggle=colorpicker]").colorpicker();
    if ($form.data('type') === 'text') {
        $form.find('textarea[name="blocks[' + id + '][text]"]').on('keyup keypress keydown input',
            Project.debounce(function () {
                $block.find('.block-content').html($(this).val().replace(/(?:\r\n|\r|\n)/g, '<br />'));
                $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
            }, 500));
        $form.find('.btn-variable').on('click',function (event) {
            event.preventDefault();
            var $field = $($(this).data('target'));
            $field.val($field.val() + $(this).data('content'));
            $block.find('.block-content').html($field.val().replace(/(?:\r\n|\r|\n)/g, '<br />'));
            $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
        });
    }
    if ($form.data('type') === 'text' || $form.data('type') === 'coupon') {
        var $fontFamily = $form.find('select[name="blocks[' + id + '][css][font-family]"]').on('change', function () {
            WebFont.load({
                google: {
                    families: [ $fontFamily.val() ]
                },
                active: function () {
                    $block.css('font-family', $fontFamily.val());
                    $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
                }
            });
        });
        WebFont.load({
            google: {
                families: [ $fontFamily.val() ]
            },
            active: function () {
                $block.css('font-family', $fontFamily.val());
            }
        });

        $form.find('select[name="blocks[' + id + '][css][font-size]"]').on('change', function () {
            $block.css('font-size', $(this).val());
            $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
        });
        $form.find('select[name="blocks[' + id + '][css][text-align]"]').on('change', function () {
            $block.css('text-align', $(this).val());
            $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
        });
        $form.find("[data-toggle=colorpicker]").on('changeColor', Project.debounce(function () {
            var $input = $form.find('input[name="blocks[' + id + '][css][color]"]');
            $block.css('color', $input.val());
            $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
        }, 500));
    }
    if ($form.data('type') === 'qr') {
        $form.find('input[name="blocks[' + id + '][text]"]').on('keyup keypress keydown input',
            Project.debounce(function () {
                $(document).trigger('postcard.' + side + '.updated', { block: id, action: 'updated' });
            }, 500));
    }
    if ($form.data('type') === 'image') {
        Project.Postcard.Edition.image(id, side);
    }
};
Project.Postcard.Edition.save = function (side, parameters) {
    var data = $('#postcard-' + side + '-form').serializeArray();
    $.each(parameters, function (key, value) {
        data.push({ name: key, value: value });
    });
    $.ajax({
        url: '/ajax/postcard/' + side + '/save.php',
        type: 'post',
        data: data
    });
};
Project.Postcard.Edition.init = function (side) {
    Project.Postcard.Edition.border($('#' + side));

    $(document).on('click', '.block', function () {
        var $block = $(this).focus();
        var $editor = $('#' + $block.attr('id') + '-edition');
        var $default = $('.block-edition-default');
        if ($default.is(':visible')) {
            $default.fadeOut(function () {
                $editor.fadeIn();
            });
        } else {
            var $current = $('.block-edition:visible');
            if ($current.attr('id') !== $editor.attr('id')) {
                $current.fadeOut(function () {
                    $editor.fadeIn();
                });
            }
        }
    }).on('click', '.btn-block-add', function (event) {
        event.preventDefault();

        var id = $(this).parents('form').find('input[name=id]').val();
        var type = $(this).data('type');

        Project.Postcard.Edition.createBlock(id, type, side);
    }).on('postcard.' + side + '.updated', function (event, data) {
        Project.Postcard.Edition.save(side, data);
    });
};