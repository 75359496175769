//############################ JS ############################

// jQuery
require('jquery/dist/jquery.js');
// jQuery UI
require('jquery-ui/ui/core.js');
require('jquery-ui/ui/widgets/draggable.js');
require('jquery-ui/ui/widgets/resizable.js');
// Webfontloader
require('webfontloader/webfontloader.js');
// Tether
require('tether/dist/js/tether.js');
// Bootstrap
require('bootstrap/dist/js/bootstrap.js');
// Toastr
require('toastr/toastr.js');
// Formvalidation
require('imports-loader?define=>false!./js/formvalidation/js/formValidation.js');
require('imports-loader?define=>false!./js/formvalidation/js/framework/bootstrap4.js');
require('imports-loader?define=>false!./js/formvalidation/js/mandatoryicon.js');
// Dropzone
require('dropzone/dist/dropzone.js');
// Bootstrap color picker
require('bootstrap-colorpicker/dist/js/bootstrap-colorpicker.js');
// bxSlider
require('bxslider/dist/jquery.bxslider.js');
// Clipboard
require('clipboard/dist/clipboard.js');
// Chart.js
require('chart.js/dist/Chart.bundle.js');
// Color thief
require('color-thief/js/color-thief.js');
// Tinycolor
require('tinycolor2/dist/tinycolor-min.js');
// Flip
require('flip/dist/jquery.flip.js');
// Project
require('./js/project/project.js');
require('./js/project/notifications.js');
require('./js/project/modal.js');
require('./js/project/request.js');
require('./js/project/postcard.js');
require('./js/project/sequencer.js');
require('./js/init.js');

//############################ CSS ############################

// Jquery UI
require('jquery-ui/themes/base/theme.css');
require('jquery-ui/themes/base/resizable.css');
// Tether
require('tether/dist/css/tether.css');
// Font Awesome
require('font-awesome/scss/font-awesome.scss');
// Animate
require('animate.css/animate.css');
// Toastr
require('toastr/toastr.scss');
// Formvalidation
require('./js/formvalidation/css/formValidation.css');
// Dropzone
require('dropzone/dist/dropzone.css');
// Bootstrap color picker
require('bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css');
// bxSlider
require('bxslider/dist/jquery.bxslider.css');
// Project
require('./scss/project.scss');